import React, { FC, useMemo, useState } from 'react'
import { Styled } from './styled'
import { CompanyInfo, Description, Document, PhoneCheckModal } from './components'
import { Button, Divider, Spacer } from '@mtsbank/ui-kit'
import { useParams } from 'react-router-dom'
import { IDataToSignItem } from './declarations'
import { BUTTONS, ERRORS, TEXT } from './constants'
import { useSubmitTask, useTask } from './api/task'

const modal = document.createElement('div')
modal.setAttribute('id', 'modal')
document.body.append(modal)

export const SignDocsV2: FC = () => {
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
  const { token } = useParams<{ token: string }>()

  const { data, isLoading: isTaskLoading, error } = useTask(token)
  const submitTask = useSubmitTask(token)

  const [dataToSign, setDataToSign] = useState<IDataToSignItem[]>([])
  const isSendEnabled = useMemo(() => {
    return dataToSign.length === (data?.task.signDocList.length || -1)
  }, [data, dataToSign])

  const onConfirmCode = async (phone: string) => {
    try {
      await submitTask('SUBMITTED', phone, dataToSign)
      alert('Документы успешно отправлены, форма автоматически закроется через 10 секунд')
      setTimeout(() => window.close(), 10000)
    } catch (error) {
      console.error('Произошла ошибки при отправке документов', error)
    }
  }

  const handleSignButtonClick = () => {
    if (!isSendEnabled) return
    data?.task.phone ? onConfirmCode(data.task.phone) : setIsPhoneModalOpen(true)
  }

  const handleCancelButtonClick = async () => {
    await submitTask('REJECTED')
    setTimeout(() => window.close(), 10000)
  }

  return (
    <Styled.Wrapper>
      {!data?.isSuccess ? (
        <>
          <section>
            <Styled.Title>
              <CompanyInfo data={data?.task} loading={isTaskLoading} error={error || undefined} />
            </Styled.Title>
          </section>
          <section>
            <Styled.Content>
              <Description />
              <Spacer />
              <Divider />
              <Document
                data={data?.task.signDocList}
                loading={isTaskLoading}
                error={error ? ERRORS.FETCH_DATA_ERRORS : ''}
                dataToSign={dataToSign}
                setDataToSign={setDataToSign}
              />
              <Spacer />
              <Button onClick={handleCancelButtonClick}>{BUTTONS.REFUSE}</Button>
              <Button disabled={!isSendEnabled} onClick={handleSignButtonClick}>
                {BUTTONS.SEND}
              </Button>
            </Styled.Content>
          </section>
        </>
      ) : (
        <section>
          <Styled.Title>
            <h1>{TEXT.TASK_DONE}</h1>
          </Styled.Title>
        </section>
      )}
      <PhoneCheckModal isOpen={isPhoneModalOpen} setIsOpen={setIsPhoneModalOpen} onConfirmCode={onConfirmCode} />
    </Styled.Wrapper>
  )
}
