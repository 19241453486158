import useSwr from 'swr'
import { otpService } from '../services/OtpService'

export const useOtp = (phone: string | null) => {
    return useSwr(
        phone ? ['phone-otp', phone] : null,
        () => otpService.sendCode(phone!)
    )
}

export const useResendOtp = (phone: string | null) => {
    const { mutate } = useOtp(phone);
    return () => mutate();
}

export const useConfirmOtp = (phone: string | null, code: string | null) => {
    return useSwr(!phone || !code ? null : ['phone-otp-confirm', code], () => otpService.confirmCode(phone!, code!));
}