import { Button, InputHelperText, Modal, TextField } from '@mtsbank/ui-kit'
import React, { FC, useEffect, useState } from 'react'
import { BUTTONS, ERRORS, LABELS, STATUS } from '../../constants'
import { useConfirmOtp, useOtp, useResendOtp } from '../../api/otp'

interface IPhoneCheckModal {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirmCode: (phone: string) => void
}

export const PhoneCheckModal: FC<IPhoneCheckModal> = ({ isOpen, setIsOpen, onConfirmCode }) => {
  const [sendConfirmOtp, setConfirmOtp] = useState<null | string>(null)
  const [sendOtp, setSendOtp] = useState<null | string>(null)
  const [code, setCode] = useState('')
  const [phone, setPhone] = useState('')

  const { data, isLoading: isSendOtpLoading, error: sendOtpError } = useOtp(sendOtp)
  const { data: otpConfirmData, isLoading: isCodeLoading, error: confirmError } = useConfirmOtp(sendOtp, sendConfirmOtp)
  const resendOtp = useResendOtp(sendOtp)

  useEffect(() => {
    if (otpConfirmData?.data.status.meta.error === 0) {
      onConfirmCode(sendOtp!)
      setIsOpen(false)
    }
  }, [otpConfirmData])

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPhone(e.target.value)
  }

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCode(e.target.value)
  }

  const handleGetCode = () => {
    setSendOtp(phone)
  }

  const handleConfirmCode = async () => {
    setConfirmOtp(code)
  }

  const handleResendCode = () => {
    resendOtp()
    setCode('')
    setConfirmOtp(null)
  }

  const handeCancelButtonClick = () => {
    setSendOtp(null)
    setConfirmOtp(null)
    setPhone('')
    setCode('')
    setIsOpen(false)
  }

  return (
    <>
      <Modal open={isOpen} onClose={handeCancelButtonClick}>
        {!sendOtp ? (
          <>
            <TextField label={LABELS.ENTER_NUMBER} value={phone} onChange={handlePhoneChange} />
            {isSendOtpLoading && <div>{STATUS.LOADING}</div>}
            {sendOtpError && <InputHelperText hasError>{JSON.stringify(sendOtpError)}</InputHelperText>}
            <Button onClick={handeCancelButtonClick} disabled={isSendOtpLoading}>
              {BUTTONS.CANCEL}
            </Button>
            <Button onClick={handleGetCode} disabled={isSendOtpLoading}>
              {BUTTONS.CONFIRM}
            </Button>
          </>
        ) : (
          <>
            <TextField label={LABELS.ENTER_SMS_CODE} value={code} onChange={handleCodeChange} />
            {otpConfirmData?.data.status.meta.error !== 0 && (
              <InputHelperText hasError>
                {ERRORS[otpConfirmData?.data.status.meta.error as keyof typeof ERRORS]}
              </InputHelperText>
            )}
            <Button variant="secondary" onClick={handleResendCode} disabled={isCodeLoading}>
              {BUTTONS.GET_NEW_CODE}
            </Button>
            <Button onClick={handleConfirmCode} disabled={isCodeLoading}>
              {BUTTONS.CONFIRM}
            </Button>
          </>
        )}
      </Modal>
    </>
  )
}
