import styled from 'styled-components'

const Wrapper = styled.div`
  width: 700px;
  background-color: #fff;
  height: 100vh;
  margin: 0 auto;
`

const Title = styled.div`
  padding: 20px;
  border-buttom: 1px solid #ccc;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 300;
`

const Content = styled.div`
  padding: 30px 20px 15px;
  background-color: #fafafa;
`

export const Styled = {
  Wrapper,
  Title,
  Content
}
