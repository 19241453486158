export const DESCRIPTION = {
  TITLE: 'Ваша задача',
  CHECK_DOC: 'Ознакомьтесь с документами',
  SIGN_IT: 'Подпишите все документы ЭЦП (можно воспользоваться кнопкой "Подписать все документы" внизу страницы)',
  SEND_DOC: 'Отправьте документы в банк, нажав на кнопку "Отправить документы в банк" внизу страницы'
}

export const LABELS = {
  ENTER_NUMBER: 'Введите номер телефона',
  ENTER_SMS_CODE: 'Введите код из СМС'
}

export const STATUS = {
  LOADING: 'Загрузка'
}

export const TEXT = {
  CHOOSE_CERTIFICATE: 'Выберите сертификат',
  SIGN_ALL_DOCS: 'Подписать все документы',
  LOADING_COMPANY_DATA: 'Загрузка документов компании',
  LOADING_COMPANY_INFO: 'Загрузка информации о компании',
  TASK_DONE: 'Документы подписаны.'
}
